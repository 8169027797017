* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Gotham;
  src: url("assets/fonts/Gotham-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: Gotham;
  src: url("assets/fonts/Gotham-Book.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url("assets/fonts/Gotham-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url("assets/fonts/Gotham-Bold.otf");
  font-weight: 600;
}
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: Gotham;
  height: 100%;
  background: #f5f5f5;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
